import React from 'react';
import { PageProps } from 'gatsby';
import { Layout } from 'components/shared/Layout';
import { TermsLayout } from 'components/Policy';
import { DOMAIN_MAP } from 'components/Policy/constants';

const Terms: React.FC<PageProps> = ({ location }) => {
  return (
    <Layout path={location.pathname}>
      <TermsLayout domain={DOMAIN_MAP.uk} />
    </Layout>
  );
};

export default Terms;
